import { useEffect, useState, useRef } from 'react';
import { Add } from '@mui/icons-material';
import { AG_GRID_LOCALE_JP } from "@ag-grid-community/locale";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const CompoundPlanGrid = ({ defaultRowData, setRowData, deleteFlag }) => {
  const gridRef = useRef(null);
  const localeText = AG_GRID_LOCALE_JP;

  useEffect(() => {
    if (gridRef.current.api) {
      let indexList = [];
      let nowData = [];
      gridRef.current.api.getSelectedNodes().forEach(node => indexList.push(node.rowIndex));
      gridRef.current.api.forEachNode(node => nowData.push(node.data));
      const updatedRowData = nowData.filter((_, index) => !indexList.includes(index));
      gridRef.current.api.setGridOption('rowData', updatedRowData);
      setRowData(updatedRowData);
    }
  }, [deleteFlag])

  const onCellValueChanged = () => {
    let nowData = [];
    gridRef.current.api.forEachNode(node => nowData.push(node.data));
    setRowData([...nowData]);
  };

  const [columnDefs] = useState([
    { headerName: '呼び名', field: 'name', editable: true, sortable: true, filter: true },
    { headerName: '計画書No', field: 'number', editable: true, sortable: true, filter: true },
    { headerName: '作成日', field: 'date', editable: true, sortable: true, filter: true },
    { headerName: '種別(N,BB,ECM,BLS)', field: 'type', editable: true, sortable: true, filter: true },
    { headerName: '季節', field: 'season', editable: true, sortable: true, filter: true },
    { headerName: '適用期間', field: 'periodsText', editable: true, sortable: true, filter: true },
    { headerName: '単価', field: 'price', editable: true, sortable: true, filter: true },
    { headerName: 'セメント種類', field: 'compoundTable.cement.type', editable: true, sortable: true, filter: true },
    { headerName: 'セメント供給元', field: 'compoundTable.cement.supplier', editable: true, sortable: true, filter: true },
    { headerName: 'セメント数量', field: 'compoundTable.cement.value', editable: true, sortable: true, filter: true },
    { headerName: '混和材①内容', field: 'compoundTable.mineralAdmixture1.content', editable: true, sortable: true, filter: true },
    { headerName: '混和材①供給元', field: 'compoundTable.mineralAdmixture1.supplier', editable: true, sortable: true, filter: true },
    { headerName: '混和材①数量', field: 'compoundTable.mineralAdmixture1.value', editable: true, sortable: true, filter: true },
    { headerName: '混和材②内容', field: 'compoundTable.mineralAdmixture2.content', editable: true, sortable: true, filter: true },
    { headerName: '混和材②供給元', field: 'compoundTable.mineralAdmixture2.supplier', editable: true, sortable: true, filter: true },
    { headerName: '混和材②数量', field: 'compoundTable.mineralAdmixture2.value', editable: true, sortable: true, filter: true },
    { headerName: '水量', field: 'compoundTable.water.value', editable: true, sortable: true, filter: true },
    { headerName: '細骨材①内容', field: 'compoundTable.fineAggregate1.content', editable: true, sortable: true, filter: true },
    { headerName: '細骨材①供給元', field: 'compoundTable.fineAggregate1.supplier', editable: true, sortable: true, filter: true },
    { headerName: '細骨材①数量', field: 'compoundTable.fineAggregate1.value', editable: true, sortable: true, filter: true },
    { headerName: '細骨材②内容', field: 'compoundTable.fineAggregate2.content', editable: true, sortable: true, filter: true },
    { headerName: '細骨材②供給元', field: 'compoundTable.fineAggregate2.supplier', editable: true, sortable: true, filter: true },
    { headerName: '細骨材②数量', field: 'compoundTable.fineAggregate2.value', editable: true, sortable: true, filter: true },
    { headerName: '細骨材③内容', field: 'compoundTable.fineAggregate3.content', editable: true, sortable: true, filter: true },
    { headerName: '細骨材③供給元', field: 'compoundTable.fineAggregate3.supplier', editable: true, sortable: true, filter: true },
    { headerName: '細骨材③数量', field: 'compoundTable.fineAggregate3.value', editable: true, sortable: true, filter: true },
    { headerName: '粗骨材①内容', field: 'compoundTable.coarseAggregate1.content', editable: true, sortable: true, filter: true },
    { headerName: '粗骨材①供給元', field: 'compoundTable.coarseAggregate1.supplier', editable: true, sortable: true, filter: true },
    { headerName: '粗骨材①数量', field: 'compoundTable.coarseAggregate1.value', editable: true, sortable: true, filter: true },
    { headerName: '粗骨材②内容', field: 'compoundTable.coarseAggregate2.content', editable: true, sortable: true, filter: true },
    { headerName: '粗骨材②供給元', field: 'compoundTable.coarseAggregate2.supplier', editable: true, sortable: true, filter: true },
    { headerName: '粗骨材②数量', field: 'compoundTable.coarseAggregate2.value', editable: true, sortable: true, filter: true },
    { headerName: '粗骨材③内容', field: 'compoundTable.coarseAggregate3.content', editable: true, sortable: true, filter: true },
    { headerName: '粗骨材③供給元', field: 'compoundTable.coarseAggregate3.supplier', editable: true, sortable: true, filter: true },
    { headerName: '粗骨材③数量', field: 'compoundTable.coarseAggregate3.value', editable: true, sortable: true, filter: true },
    { headerName: '混和剤①内容', field: 'compoundTable.chemicalAdmixture1.content', editable: true, sortable: true, filter: true },
    { headerName: '混和剤①供給元', field: 'compoundTable.chemicalAdmixture1.supplier', editable: true, sortable: true, filter: true },
    { headerName: '混和剤①数量', field: 'compoundTable.chemicalAdmixture1.value', editable: true, sortable: true, filter: true },
    { headerName: '混和剤②内容', field: 'compoundTable.chemicalAdmixture2.content', editable: true, sortable: true, filter: true },
    { headerName: '混和剤②供給元', field: 'compoundTable.chemicalAdmixture2.supplier', editable: true, sortable: true, filter: true },
    { headerName: '混和剤②数量', field: 'compoundTable.chemicalAdmixture2.value', editable: true, sortable: true, filter: true },
    { headerName: '混和剤③内容', field: 'compoundTable.chemicalAdmixture3.content', editable: true, sortable: true, filter: true },
    { headerName: '混和剤③供給元', field: 'compoundTable.chemicalAdmixture3.supplier', editable: true, sortable: true, filter: true },
    { headerName: '混和剤③数量', field: 'compoundTable.chemicalAdmixture3.value', editable: true, sortable: true, filter: true },
  ]);

  // 行を追加する関数
  const addRow = () => {
    const addData = {
      compoundTable: {
        cement: {type: '', supplier: '', value: 0},
        chemicalAdmixture1: {content: '', supplier: '', value: 0},
        chemicalAdmixture2: {content: '', supplier: '', value: 0},
        chemicalAdmixture3: {content: '', supplier: '', value: 0},
        coarseAggregate1: {content: '', supplier: '', value: 0},
        coarseAggregate2: {content: '', supplier: '', value: 0},
        coarseAggregate3: {content: '', supplier: '', value: 0},
        fineAggregate1: {content: '', supplier: '', value: 0},
        fineAggregate2: {content: '', supplier: '', value: 0},
        fineAggregate3: {content: '', supplier: '', value: 0},
        mineralAdmixture1: {content: '', supplier: '', value: 0},
        mineralAdmixture2: {content: '', supplier: '', value: 0},
        water: {value: 0}
      },
      date: "",
      name: "",
      number: "",
      periodsText: "",
      price: 0,
      season: "",
      type: ""
    }
    let nowData = [];
    gridRef.current.api.forEachNode(node => nowData.push(node.data));
    gridRef.current.api.setGridOption('rowData', [...nowData, addData]);
    setRowData([...nowData, addData]);
  };


  return (
    <>
    <div className="ag-theme-quartz" style={{ height: "calc(100vh - 410px)" }}>
      <AgGridReact
        ref={gridRef}
        rowSelection={{mode: "multiRow"}}
        localeText={localeText}
        columnDefs={columnDefs}
        rowData={defaultRowData}
        defaultColDef={{
          resizable: true, // 列のサイズ変更のみ有効にする
          editable: true,
          sortable: true, // グリッド全体の並べ替えを無効化
          suppressMovable: true, // 列のドラッグ＆ドロップ移動を無効化
          filter: true, // デフォルトで全列にフィルターを有効化
        }}
        onCellValueChanged={onCellValueChanged}
      />
    </div>
    <div style={{ height: 40, position: "relative", border: "1px solid color-mix(in srgb, transparent, #181d1f 15%)", borderTop: "none", display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", justifyContent: "center", width: 50, cursor: "pointer" }} onClick={() => addRow()}><Add color="info" /></div>
    </div>
    </>
  );
};

export default CompoundPlanGrid;
