import React, {useState, createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import httpHelper from '../http-helper';

let dialogCallback = null;

const useAppState = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [dialog, setDialog] = useState({
    type: null,
    title: '',
    body: ''
  });

  let initialLoginInfo = null;
  const loginInfoSession = localStorage.getItem('loginInfo');
  if (loginInfoSession) {
    try {
      initialLoginInfo = JSON.parse(loginInfoSession);
    } catch(e) {
    }
  }
  const [loginInfo, setLoginInfo] = useState(initialLoginInfo);
  const [pathAfterLogin, setPathAfterLogin] = useState('/');

  const login = (loginInfo) => {
    localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
    setLoginInfo(loginInfo);
  };

  const logout = () => {
    localStorage.clear();
    setLoginInfo(null);
  };

  const showMessageDialog = (title, body, callback, foot) => {
    dialogCallback = callback;
    setDialog({type: 'Message', title: title, body: body, foot});
  };

  const showConfirmDialog = (title, body, callback, leftFoot, rightFoot) => {
    dialogCallback = callback;
    setDialog({type: 'Confirm', title: title, body: body, leftFoot, rightFoot});
  };

  const onCloseDialog = result => {
    setDialog({type: null, title: '', body: ''});
    if (dialogCallback) {
      dialogCallback(result);
      dialogCallback = null;
    }
  };

  const http = (useFormData = false, responseBlob = false) => {
    return httpHelper(process.env.REACT_APP_API_BASE_URL, useFormData, responseBlob);
  };

  const handleHttpError = (err) => {
    if (!err.response) {
      console.error(err);
      showMessageDialog('エラー', '予期せぬエラーが発生しました。');
    } else if (err.response.status === 401) {
      showMessageDialog('エラー', err.response.data.message, () => logout());
    } else if (err.response.status === 428) {
      showMessageDialog('ログイン失敗', '認証コードが違います');
    } else {
      const hint = err.response && err.response.data ? err.response.data.message : null;
      const messages = {
        400: '不正なリクエストのため、データを取得できません。',
        404: '対象データが見つかりません。',
        500: 'アプリケーション・エラーが発生しました。',
      };
      const message = messages[err.response.status] ? messages[err.response.status] : '予期せぬエラーが発生しました。';
      showMessageDialog('エラー', message + (hint ? `<br>(${hint})` : ''));
    }
  };

  return {
    isLoading,
    setIsLoading,
    isDrawerOpen,
    setIsDrawerOpen,
    loginInfo,
    pathAfterLogin,
    setPathAfterLogin,
    login,
    logout,
    dialog,
    showMessageDialog,
    showConfirmDialog,
    onCloseDialog,
    http,
    handleHttpError,
  };
};

const AppContext = createContext();
const AppContextProvider = ({children}) => {
  const value = useAppState();
  return (<AppContext.Provider value={value}>{children}</AppContext.Provider>);
};
AppContextProvider.propTypes = {
  children: PropTypes.any,
};
const useAppContext = () => useContext(AppContext);

export {useAppState, AppContextProvider};
export default useAppContext;
