import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Toolbar } from '@mui/material';

import AppHeader from '../common/components/AppHeader';
import AppDrawer from '../common/components/AppDrawer';
import ConstructListPage from './delivery/ConstructListPage';
import DeliveryListPage from './delivery/DeliveryListPage';
import EditDeliveryPage from './delivery/EditDeliveryPage';
import EditDeliveryWithoutParamsPage from './delivery/EditDeliveryWithoutParamsPage';
import ViewDeliveryPage from './delivery/ViewDeliveryPage';
import DeliveryGroupListPage from './delivery/DeliveryGroupListPage';
import EditPrecastConcretePage from './delivery/EditPrecastConcretePage';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
  },
}));

const pages = [
  { name: '施工会社・現場名一覧', path: '/concrete-plant/construct' },
];

const ConcretePlantUser = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppHeader />
      <AppDrawer pages={pages}></AppDrawer>
      <div className={classes.content}>
        <Toolbar variant="dense" />
        <Routes>
          <Route path="/" element={<Navigate to="./construct" />} />
          <Route path="/construct" element={<ConstructListPage />} />
          <Route path="/construct/:constructId/delivery" element={<DeliveryGroupListPage />} />
          <Route path="/construct/:constructId/delivery-all" element={<DeliveryListPage />} />
          <Route path="/construct/delivery/edit" element={<EditDeliveryWithoutParamsPage />} />
          <Route path="/construct/:constructId/delivery/:deliveryId/edit" element={<EditDeliveryPage />} />
          <Route path="/construct/:constructId/delivery/:deliveryId/view" element={<ViewDeliveryPage />} />
          <Route path="/construct/:constructId/delivery/precastConcrete" element={<EditPrecastConcretePage />} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

export default ConcretePlantUser;
