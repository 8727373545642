import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Box, Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

import AppPrimaryArea from '../../common/components/AppPrimaryArea';
import AppTable from '../../common/components/AppTable';
import { AppSelect } from '../../common/components/AppFormField';
import useAppContext from '../../common/hooks/useAppContext';
import { concreteTypeToLabel, formatCompoundPlan } from '../../common/data-helper';
import { toFlatJson } from '../../common/form-helper';
import { master } from './components/compound-plan-master';
import CompoundPlanDownload from './components/CompoundPlanDownload';
import CompoundPlanImport from './components/CompoundPlanImport';
import CompoundPlanGrid from './components/CompoundPlanGrid';

const tableFields = [
  { name: "type", label: "コンクリート種別", type: "text", format: concreteTypeToLabel },
  { name: "name", label: "呼び方", type: "text" },
  { name: "season", label: "適用季節", type: "text" },
  { name: "periodsText", label: "適用期間", type: "text" },
  { name: "date", label: "配合計画作成日", type: "text" },
];

const useStyles = makeStyles(theme => ({
  root: {},
  gridButtonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 8
  }
}));

const queryConstruct = async (app, constructId) => app.http().get(`/master/construct/${constructId}`);

const queryConcretePlants = async (app, plantIds) => {
  const res = await app.http().get('/master/concrete-plant');
  const filterdConcretePlants = res.filter((d) => plantIds.includes(d._id)).map((d) => ({ label: d.plantName, value: d._id, corporateId: d.corporateId }));
  if (filterdConcretePlants.length === 0) {
    filterdConcretePlants.push({ label: '（プラントが設定されていません）', value: '(none)' })
  }
  return filterdConcretePlants;
};

const queryCompoundPlans = async (app, constructId, selectedPlantId) => {
  let result1 = [];
  let result2 = [];
  if (selectedPlantId !== '(none)') {
    const res3 = await app.http().get('/master/compound-plan', { constructId: constructId, plantId: selectedPlantId });
    const flatCompoundPlans1 = res3.map((d) => {
      const flatData = toFlatJson(d);
      flatData.label = formatCompoundPlan(d);
      flatData.periodsText = d.periods.map((x) => x.from + '-' + x.to).join(', ');
      return flatData;
    });
    flatCompoundPlans1.sort((x, y) => x.label.localeCompare(y.label));
    result1 = flatCompoundPlans1;
    const flatCompoundPlans2 = res3.map((d) => {
      const flatData = d;
      flatData.label = formatCompoundPlan(d);
      flatData.periodsText = d.periods.map((x) => x.from + '-' + x.to).join(', ');
      return flatData;
    });
    flatCompoundPlans2.sort((x, y) => x.label.localeCompare(y.label));
    result2 = flatCompoundPlans2;
  }
  return [result1, result2];
};

const CompoundPlanListPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();
  const params = useParams();
  const [construct, setConstruct] = useState({});
  const [compoundPlans, setCompoundPlans] = useState([]);
  const [compoundPlansGrid, setCompoundPlansGrid] = useState([]);
  const [concretePlants, setConcretePlants] = useState([]);
  const [plantId, setPlantId] = useState('');
  const plant = plantId ? concretePlants.find((d) => d.value === plantId) : {};

  useEffect(() => {
    const query = async () => {
      try {
        if (params.constructId) {
          app.setIsLoading(true);
          const res1 = await queryConstruct(app, params.constructId);
          setConstruct(res1);
          const res2 = await queryConcretePlants(app, res1.plantIds);
          setConcretePlants(res2);
          setPlantId(res2[0].value);
          const [res3, res3_grid] = await queryCompoundPlans(app, params.constructId, res2[0].value);
          setCompoundPlans(res3);
          setCompoundPlansGrid(res3_grid);
          setDefaultRowData(JSON.parse(JSON.stringify(res3_grid)));
          setRowData(JSON.parse(JSON.stringify(res3_grid)));
        }
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, [params.constructId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePlant = async (event) => {
    try {
      setPlantId(event.target.value);
      const [result, result_grid] = await queryCompoundPlans(app, params.constructId, event.target.value);
      setCompoundPlans(result);
      setCompoundPlansGrid(result_grid);
      setDefaultRowData(JSON.parse(JSON.stringify(result_grid)));
      setRowData(JSON.parse(JSON.stringify(result_grid)));
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const [defaultRowData, setDefaultRowData] = useState([]);
  const [deleteCompoundPlanFlag, setDeleteCompoundPlanFlag] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [updateCompoundPlan, setUpdateCompoundPlan] = useState(false);
  const handleCancelCompoundPlan = () => {
    setDefaultRowData(JSON.parse(JSON.stringify(compoundPlansGrid)));
    setRowData(JSON.parse(JSON.stringify(compoundPlansGrid)));
    setUpdateCompoundPlan(false);
  };
  const handleUpdateCompoundPlan = async () => {
    const data = rowData.map(r => {
      delete r.compoundPlanEvidence;
      delete r.periods;
      delete r.ratios;
      delete r.label;
      const flatData = toFlatJson(r);

      Object.keys(flatData).forEach(name => {
        let value = flatData[name];
        const index = master.itemNames.indexOf(name);
        if (index !== -1) {
          if (master.itemTypes[index] === 'number') {
            value = Number(value);
          } else {
            if (value) {
              if (value.startsWith('=')) {
                value = value.substring(1);
              }
              if (value.startsWith('"') && value.endsWith('"')) {
                value = value.slice(1, -1);
              }
            } else {
              value = "";
            }
          }
        }

        flatData[name] = value;
      })

      const periods = flatData.periodsText ? flatData.periodsText.split(',') : [];
      flatData.periods = periods.map((d) => {
        const [from, to] = d.trim().split('-');
        return { from, to };
      });
      return flatData;
    })

    try {
      app.setIsLoading(true);
      const req = {
        constructId: params.constructId,
        corporateId: plant.corporateId,
        plantId: plant.value,
        plantName: plant.label,
        data,
      };
      await app.http().post('/master/compound-plan/update', req);
      app.showMessageDialog('確認', '配合計画の追加・編集処理が完了しました。', async () => {
        const [res, res_grid] = await queryCompoundPlans(app, params.constructId, plantId);
        setCompoundPlans(res);
        setCompoundPlansGrid(res_grid);
        setDefaultRowData(JSON.parse(JSON.stringify(res_grid)));
        setRowData(JSON.parse(JSON.stringify(res_grid)));
        setUpdateCompoundPlan(false);
      });
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  }
  const handleImport = async (data) => {
    try {
      app.setIsLoading(true);
      const req = {
        constructId: params.constructId,
        corporateId: plant.corporateId,
        plantId: plant.value,
        plantName: plant.label,
        data,
      };
      await app.http().post('/master/compound-plan/import', req);
      app.showMessageDialog('確認', '配合計画のインポート処理が完了しました。', async () => {
        const [res, res_grid] = await queryCompoundPlans(app, params.constructId, plantId);
        setCompoundPlans(res);
        setCompoundPlansGrid(res_grid);
        setDefaultRowData(JSON.parse(JSON.stringify(res_grid)));
        setRowData(JSON.parse(JSON.stringify(res_grid)));
      });
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const handleClear = async (data) => {
    app.showConfirmDialog('削除確認', `配合計画の情報をクリアしようとしています。</br>実行してよろしいですか？`, async (result) => {
      if (result) {
        try {
          app.setIsLoading(true);
          await app.http().delete(`/master/compound-plan/clear`, {
            constructId: params.constructId,
            plantId: plantId,
          });
          const [res, res_grid] = await queryCompoundPlans(app, params.constructId, plantId);
          setCompoundPlans(res);
          setCompoundPlansGrid(res_grid);
          setDefaultRowData(JSON.parse(JSON.stringify(res_grid)));
          setRowData(JSON.parse(JSON.stringify(res_grid)));
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      }
    });

  };

  const handleEditItem = (item) => () => navigate(`./${item._id}`);

  const breadcrumbs = [
    { name: '現場一覧', path: '../manage-construct' },
    { name: construct ? construct.constructName : '', path: `../manage-construct/${params.constructId}` },
  ];

  const rowActions = [
    { name: '詳細', icon: <ChevronRight color="primary" />, handler: handleEditItem },
  ];

  return (
    <div className={classes.root}>
      <AppPrimaryArea title={'配合計画一覧' + (construct ? `　${construct.constructName} ` : '')} breadcrumbs={breadcrumbs} size="xl">
        <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
          <div style={{ display: 'flex' }}>
            <CompoundPlanImport onSubmit={handleImport} />
            &nbsp;&nbsp;
            <Button variant="outlined" color="primary" onClick={handleClear}>クリア</Button>
          </div>
          <div>
            <Button color="info" variant="contained" onClick={() => setUpdateCompoundPlan(true)} style={{ height: 40, marginRight: 16 }}>配合計画を追加・編集する</Button>
            <Button color="error" variant="contained" onClick={() => setDeleteCompoundPlanFlag(!deleteCompoundPlanFlag)} style={{ height: 40 }}>削除</Button>
          </div>
        </Box>
      </AppPrimaryArea>
      <Container component="main" maxWidth="xl" sx={{ py: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ minWidth: 400 }}>
            <AppSelect name="test" label="プラント" value={plantId} selectList={concretePlants} onChange={handleChangePlant} />
          </Box>
          <Box sx={{ flex: 1 }}></Box>
          <Box sx={{ marginTop: 'auto', minWidth: 175 }}>
            <CompoundPlanDownload data={compoundPlans} name={`配合計画_${construct.constructName}_${plant.label}`} />
          </Box>
        </Box>
        {
          updateCompoundPlan ?
            <div style={{ marginTop: 8 }}>
              <CompoundPlanGrid defaultRowData={defaultRowData} setRowData={setRowData} deleteFlag={deleteCompoundPlanFlag} />
              <div className={classes.gridButtonGroup}>
                <Button color="info" variant="outlined" onClick={handleCancelCompoundPlan} style={{ marginRight: 16 }}>キャンセル</Button>
                <Button color="info" variant="contained" onClick={handleUpdateCompoundPlan}>確定する</Button>
              </div>
            </div>
            :
            <AppTable tableFields={tableFields} data={compoundPlans} rowActions={rowActions} />
        }
      </Container>
    </div>
  );
};

export default CompoundPlanListPage;
