import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { DialogContent, Dialog, DialogTitle } from '@mui/material';
import { WarningAmber } from '@mui/icons-material';

import AppForm from '../../common/components/AppForm';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 700
  },
  title: {
    display: "flex",
    alignItems: "center",
    lineHeight: "unset",
    color: "red"
  }
}));

const ErrorMessageDialog = ({ open, onClose, onSubmit, data }) => {
  const classes = useStyles();

  const [defaultData, setDefaultData] = useState({});
  const [formFields, setFormFields] = useState([
    { name: "plantName", label: "プラント名", type: "text" },
    { name: "plantAddress", label: "住所", type: "text" },
    { name: "constructName", label: "工事名", type: "text" },
    { name: "constructAddress", label: "現場所在地", type: "text" },
    { name: "architecture", label: "土木/建築", type: "text" }
  ]);
  useEffect(() => {
    if (data) {
      const newData = formFields.map(e => {
        const v = data[e.name];
        defaultData[e.name] = v.value;

        if (v.error) {
          return {...e, errorBorder: true};
        } else {
          return e;
        }
      });
      setDefaultData(defaultData);
      setFormFields(newData);
    }
  }, [data]);

  const handleUpdate = (values) => {
    onSubmit(values);
  };

  const submitActions = [
    { name: '確定', handler: handleUpdate },
    { name: 'キャンセル', handler: onClose, variant: 'outlined', skipValidation: true, color: 'primary' }
  ];

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle className={classes.title}><WarningAmber color="warning" style={{marginRight: 8}}/> 入力内容に誤りがあるかもしれません！再度確認をしてください</DialogTitle>
      <DialogContent>
      <AppForm formFields={formFields} data={defaultData} submitActions={submitActions} columns={{ xs: 1, md: 1 }} />
      </DialogContent>
    </Dialog>
  );
};

export default ErrorMessageDialog;
