import React from 'react';
import { makeStyles } from '@mui/styles';
import { DialogContent, Dialog, DialogTitle } from '@mui/material';
import * as yup from 'yup';

import useAppContext from '../hooks/useAppContext';
import AppForm from './AppForm';
import { loginIdRegex } from '../data-helper';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 300
  }
}));

const AddUserDialog = ({ open, onClose, selectList }) => {
  const classes = useStyles();
  const app = useAppContext();

  const loginIdSchema = yup.string()
    .required('ログインIDを入力してください')
    .max(100, 'ログインIDは${max}文字以下で指定してください')
    .matches(loginIdRegex, 'ログインIDで指定してください')
    .test('loginIdCheck', 'IDがすでに使用されています', (value) => !selectList.loginId.filter(e => e.value === value)[0]);
  const passwordSchema = yup.string()
    .required('パスワードを入力してください')
    .min(8, 'パスワードは${min}文字以上で指定してください')
    .max(100, 'パスワードは${max}文字以下で指定してください')
    .matches(loginIdRegex, '半角英数字・記号を含む8文字以上で入力してください');
  const displayNameSchema = yup.string()
    .required('ログイン表示名を入力してください')
    .max(100, 'ログイン表示名は${min}文字以下で指定してください');
  const formFields = [
    { name: "loginId", label: "ログインID", type: "text", schema: loginIdSchema, offAutoComplete: true },
    { name: "password", label: "パスワード", type: "password", schema: passwordSchema, offAutoComplete: true },
    { name: "displayName", label: "ログイン表示名", type: "text", schema: displayNameSchema, offAutoComplete: true },
    { name: "corporateId", label: "企業ID", type: "select", required: true },
    { name: "role", label: "ロール", type: "select", required: true }
  ];

  const handleAdd = async (values) => {
    try {
      app.setIsLoading(true);
      await app.http().post('/master/user', values);
      app.showMessageDialog('', 'ユーザーを追加しました', null, 'OK');
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const submitActions = [
    { name: '追加', handler: handleAdd },
    { name: 'キャンセル', handler: onClose, variant: 'outlined', skipValidation: true, color: 'primary' }
  ];

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>ユーザー追加</DialogTitle>
      <DialogContent>
        <AppForm formFields={formFields} submitActions={submitActions} selectList={selectList} columns={{ xs: 1, md: 1 }} />
      </DialogContent>
    </Dialog>
  );
};

export default AddUserDialog;
