import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { DialogContent, Dialog, DialogTitle } from '@mui/material';
import * as yup from 'yup';

import useAppContext from '../hooks/useAppContext';
import AppForm from './AppForm';
import { loginIdRegex } from '../data-helper';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 700
  }
}));

const passwordSchema = yup.string()
  .min(8, 'パスワードは${min}文字以上で指定してください')
  .max(100, 'パスワードは${max}文字以下で指定してください')
  .matches(loginIdRegex, '半角英数字・記号を含む8文字以上で入力してください');
const displayNameSchema = yup.string()
  .required('ログイン表示名を入力してください')
  .max(100, 'ログイン表示名は${min}文字以下で指定してください');
const loginFormFields = [
  { name: "loginId", label: "ログインID", type: "select", required: true }
];
const formFields = [
  { name: "loginId", label: "ログインID", type: "select", required: true },
  { name: "password", label: "パスワード", type: "password", schema: passwordSchema, offAutoComplete: true },
  { name: "displayName", label: "ログイン表示名", type: "text", schema: displayNameSchema, offAutoComplete: true },
  { name: "corporateId", label: "企業ID", type: "select", required: true },
  { name: "role", label: "ロール", type: "select", required: true }
];

const UpdateUserDialog = ({ open, onClose, selectList }) => {
  const classes = useStyles();
  const app = useAppContext();
  const [loginData, setLoginData] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (loginData && loginData.loginId) {
      const selected = selectList.loginId.filter(e => e.value === loginData.loginId)[0];
      setData(selected?.data);
    }
  }, [loginData]);

  const handlers = {
    loginId: {
      onChange: (event, values) => {
        const selected = selectList.loginId.filter(e => e.value === event.target.value)[0];
        setData(selected?.data);
      }
    }
  };

  const handleUpdate = async (values) => {
    try {
      app.setIsLoading(true);
      await app.http().put('/master/user', values);
      app.showMessageDialog('', 'ユーザー情報を更新しました', null, 'OK');
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const close = () => {
    onClose();
    setLoginData(null);
    setData(null);
  };

  const submitActions = [
    { name: '更新', handler: handleUpdate },
    { name: 'キャンセル', handler: close, variant: 'outlined', skipValidation: true, color: 'primary' }
  ];

  return (
    <Dialog open={open} onClose={close} classes={{ paper: classes.paper }}>
      <DialogTitle>ユーザー編集</DialogTitle>
      <DialogContent>
        {loginData && loginData.loginId ? 
          <AppForm formFields={formFields} data={data} submitActions={submitActions} selectList={selectList} handlers={handlers} columns={{ xs: 1, md: 1 }} />
        : <AppForm formFields={loginFormFields} selectList={selectList} watch={setLoginData} columns={{ xs: 1, md: 1 }} />}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateUserDialog;
