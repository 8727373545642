import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Checkbox, Button } from '@mui/material';
import { Key, Smartphone, ContentCopy, CheckCircleOutline, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import * as yup from 'yup';

import useAppContext from '../common/hooks/useAppContext';
import AppForm from '../common/components/AppForm';

const useStyles = makeStyles(theme => ({
  background: {
    height: "100vh",
    background: "rgb(248, 250, 251)",
    overflow: "auto"
  },
  space6: {
    marginTop: theme.spacing(6),
  },
  loginContainer: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    background: "#fff",
    padding: "24px 150px",
    borderRadius: 8
  },
  header: {
    '& > div:nth-child(1)': {
      display: 'flex',
      fontWeight: 700
    },
    '& > div:nth-child(2)': {
      fontSize: 14
    }
  },
  step1: {
    display: "flex",
    margin: "24px 0",
    minHeight: 70
  },
  smartphone: {
    display: "flex",
    alignItems: "center",
    background: "#e6f4ff",
    padding: 12,
    marginRight: 8,
    height: 30,
    borderRadius: 4
  },
  info1: {
    '& > div:nth-child(1)': {
      height: 30,
      fontWeight: 500
    },
    '& > div:nth-child(2)': {
      fontSize: 14
    }
  },
  qrCode: {
    display: "flex",
    justifyContent: "center",
    margin: "24px 0"
  },
  fold: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    marginBottom: 24
  },
  textHasBorder1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #000",
    borderRadius: 4,
    marginBottom: 24,
    fontSize: 14,
    padding: 8
  },
  textHasBorder2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #000",
    borderRadius: 4,
    marginBottom: 24,
    fontSize: 14,
    padding: 8,
    '& > div:nth-child(1)': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  copy: {
    cursor: "pointer",
    height: 14
  },
  checkCircleContainer: {
    display: "flex",
    justifyContent: "center"
  },
  checkCircle: {
    borderRadius: "50%",
    background: "#b7eb8f",
    margin: "24px 0",
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  info31: {
    textAlign: "center"
  },
  info32: {
    textAlign: "center",
    fontSize: 14,
    margin: "16px 0 24px 0"
  },
  checkLine: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 500,
    height: 20,
    marginBottom: 24
  }
}));

const codeSchema = yup.string()
  .required('認証コードを入力してください')
  .length(6, '認証コードは6桁である必要があります')
  .matches( /^[0-9]+$/, '認証コードは数字のみ入力許可する');
const loginFields = [
  { name: "userCode", label: "認証コードを入力", type: "text", required: true, offAutoComplete: true, schema: codeSchema },
];

const SetupMFAPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [foldFlag, setFoldFlag] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleVerify = async (values) => {
    try {
      const data = values;
      data.session = localStorage.getItem('Session')
      app.setIsLoading(true);
      await app.http().post('/auth/setup-mfa', data);
      setStep(3);
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const submitActions = [
    { name: '確認', handler: handleVerify, fullWidth: true },
  ];

  return (
    <div className={classes.background}>
      <div className={classes.space6} />
      <Container component="main" maxWidth="sm" className={classes.loginContainer}>
        <div style={{ padding: "24px 100px" }}>
          <div className={classes.header}>
            <div>
              <Key style={{ marginRight: 8 }} />
              二段階認証の設定
            </div>
            <div>
              アカウントのセキュリティを強化するために、二段階認証を設定してください。
            </div>
          </div>

          {step === 1 && (
            <>
              <div className={classes.step1}>
                <div className={classes.smartphone}>
                  <Smartphone color="info" />
                </div>
                <div className={classes.info1}>
                  <div>1. 認証アプリをインストール</div>
                  <div>
                    Google認証システムやMicrosoft Authenticatorなどの認証アプリをスマートフォンにインストールしてください。
                  </div>
                </div>
              </div>
              <Button
                style={{ padding: "8px 22px" }}
                variant="contained"
                color="info"
                onClick={() => setStep(2)}
                fullWidth
              >
                次へ進む
              </Button>
            </>
          )}

          {step === 2 && (
            <>
              <div className={classes.qrCode}>
                <QRCode value={localStorage.getItem('qrcodeStr')} />
              </div>

              <div className={classes.fold}>
                QRコードが入力できない方はコチラをクリック
                {foldFlag ? <KeyboardArrowDown style={{cursor: "pointer"}} onClick={() => setFoldFlag(false)} /> : <KeyboardArrowUp style={{cursor: "pointer"}} onClick={() => setFoldFlag(true)} />}
              </div>

              {
                !foldFlag &&
                <>
                  <div className={classes.textHasBorder1}>
                    認証アプリでQRコードをスキャンできない場合は、以下のシークレットキーを手動で入力してください。
                  </div>

                  <div className={classes.textHasBorder2}>
                    <div>{localStorage.getItem('SecretCode')}</div>
                    <div
                      className={classes.copy}
                      onClick={() => navigator.clipboard.writeText(localStorage.getItem('SecretCode'))}
                    >
                      <ContentCopy fontSize="14" />
                    </div>
                  </div>
                </>
              }

              <AppForm formFields={loginFields} submitActions={submitActions} columns={{ xs: 1, md: 1 }} />
            </>
          )}

          {step === 3 && (
            <>
              <div className={classes.checkCircleContainer}>
                <div className={classes.checkCircle}>
                  <CheckCircleOutline style={{ color: "#389e0d" }} />
                </div>
              </div>

              <div>
                <div className={classes.info31}>設定完了</div>
                <div className={classes.info32}>
                  二段階認証の設定が完了しました。次回のログインから有効になります。
                </div>
              </div>
              <div className={classes.checkLine}>
                <Checkbox
                  checked={isConfirmed}
                  onChange={(e) => setIsConfirmed(e.target.checked)}
                />
                シークレットキーを保存しました
              </div>
              <Button
                style={{ padding: "8px 22px" }}
                variant="contained"
                color="info"
                fullWidth
                disabled={!isConfirmed}
                onClick={() => navigate("/verify-code")}
              >
                完了
              </Button>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default SetupMFAPage;
