import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import useAppContext from '../common/hooks/useAppContext';
import AppForm from '../common/components/AppForm';

const useStyles = makeStyles(theme => ({
  background: {
    height: "100vh",
    background: "rgb(248, 250, 251)",
    overflow: "auto"
  },
  space6: {
    marginTop: theme.spacing(6),
  },
  loginContainer: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    background: "#fff",
    padding: "24px 150px",
    borderRadius: 8
  },
  loginTitle: {
    fontSize: "24px !important",
    fontWeight: "700 !important",
    marginBottom: "24px !important"
  },
}));

const codeSchema = yup.string()
  .required('認証コードを入力してください')
  .length(6, '認証コードは6桁である必要があります')
  .matches( /^[0-9]+$/, '認証コードは数字のみ入力許可する');
const loginFields = [
  { name: "loginId", label: "ログインID", type: "text", readonly: true, offAutoComplete: true },
  { name: "userCode", label: "認証コード", type: "text", required: true, offAutoComplete: true, schema: codeSchema },
];

const VerifyCodePage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      app.setIsLoading(true);
      const data = values;
      data.password = localStorage.getItem('password');
      const res = await app.http().post('/auth/login-with-mfa', data);

      if (res.AuthenticationResult) {
        localStorage.clear();
        localStorage.setItem('access_token', res.AuthenticationResult.AccessToken);
        localStorage.setItem('refresh_token', res.AuthenticationResult.RefreshToken);
        const user = await app.http().get('/user');
        app.login(user);
        navigate(app.pathAfterLogin);
      }
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  const submitActions = [
    { name: '認証する', handler: handleSubmit, fullWidth: true },
  ];

  return (
    <div className={classes.background}>
      <div className={classes.space6} />
      <Container component="main" maxWidth="sm" className={classes.loginContainer}>
        <div style={{padding: "24px 100px"}}>
          <Typography align="center" className={classes.loginTitle}>
          CO2見える化プラットフォーム
          </Typography>

          <Typography align="left" style={{marginBottom: 24}}>
          アプリに表示されている認証コードを入力してください。
          </Typography>

          <AppForm data={{loginId: localStorage.getItem('loginId')}} formFields={loginFields} submitActions={submitActions}  columns={{ xs: 1, md: 1 }} />
        </div>
      </Container>
    </div>
  );
};

export default VerifyCodePage;
