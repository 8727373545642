import { useEffect, useState, useRef } from 'react';
import { Add } from '@mui/icons-material';
import { AG_GRID_LOCALE_JP } from "@ag-grid-community/locale";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const ConcretePlantGrid = ({ defaultRowData, setRowData, deleteFlag }) => {
  const gridRef = useRef(null);
  const localeText = AG_GRID_LOCALE_JP;

  useEffect(() => {
    if (gridRef.current.api) {
      let indexList = [];
      let nowData = [];
      gridRef.current.api.getSelectedNodes().forEach(node => indexList.push(node.rowIndex));
      gridRef.current.api.forEachNode(node => nowData.push(node.data));
      const updatedRowData = nowData.filter((_, index) => !indexList.includes(index));
      gridRef.current.api.setGridOption('rowData', updatedRowData);
      setRowData(updatedRowData);
    }
  }, [deleteFlag])

  const onCellValueChanged = () => {
    let nowData = [];
    gridRef.current.api.forEachNode(node => nowData.push(node.data));
    setRowData([...nowData]);
  };

  const [columnDefs] = useState([
    { headerName: '工場ID', field: '_id', editable: true, sortable: true, filter: true },
    { headerName: '企業ID', field: 'corporateId', editable: true, sortable: true, filter: true },
    { headerName: '製造会社名', field: 'plantName', editable: true, sortable: true, filter: true },
    { headerName: '製造会社住所', field: 'address', editable: true, sortable: true, filter: true },
    { headerName: '認証番号', field: 'certificateNumber', editable: true, sortable: true, filter: true },
    { headerName: '認証に関わるJIS番号', field: 'JISnumber', editable: true, sortable: true, filter: true },
    { headerName: '認証書添付', field: 'JISEvidence', editable: true, sortable: true, filter: true },
  ]);

  // 行を追加する関数
  const addRow = () => {
    let nowData = [];
    gridRef.current.api.forEachNode(node => nowData.push(node.data));
    gridRef.current.api.setGridOption('rowData', [...nowData, {}]);
    setRowData([...nowData, {}]);
  };


  return (
    <>
    <div className="ag-theme-quartz" style={{ height: "calc(100vh - 400px)" }}>
      <AgGridReact
        ref={gridRef}
        rowSelection={{mode: "multiRow"}}
        localeText={localeText}
        columnDefs={columnDefs}
        rowData={defaultRowData}
        defaultColDef={{
          resizable: true, // 列のサイズ変更のみ有効にする
          editable: true,
          sortable: true, // グリッド全体の並べ替えを無効化
          suppressMovable: true, // 列のドラッグ＆ドロップ移動を無効化
          filter: true, // デフォルトで全列にフィルターを有効化
        }}
        onCellValueChanged={onCellValueChanged}
      />
    </div>
    <div style={{ height: 40, position: "relative", top: 38, border: "1px solid color-mix(in srgb, transparent, #181d1f 15%)", borderTop: "none", display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", justifyContent: "center", width: 50, cursor: "pointer" }} onClick={() => addRow()}><Add color="info" /></div>
    </div>
    </>
  );
};

export default ConcretePlantGrid;
